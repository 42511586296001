import Vue from 'vue'

const url = process.env.VUE_APP_MAIN_URL
const token = process.env.VUE_APP_TOKEN

export const recipient = () => {
  return Vue.axios.get<{ recipients: { name: string; token: string }[] }>(
    `${url}/v1/line/listRecipient`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )
}

export const sendNotify = (name: string, message: string) => {
  return Vue.axios.post<string>(
    `${url}/v1/line/send`,
    { name, message },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  )
}

export const sendNotifyWithExtra = (
  name: string,
  message: string,
  imageUrl?: string,
  stickerPackageId?: string,
  stickerId?: string
) => {
  const data: { [key: string]: string } = { name, message }
  if (imageUrl) {
    data.imageThumbnail = imageUrl
    data.imageFullsize = imageUrl
  }
  if (stickerPackageId && stickerId) {
    data.stickerPackageId = stickerPackageId
    data.stickerId = stickerId
  }
  return Vue.axios.post<string>(`${url}/v1/line/sendWithOptions`, data, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
}

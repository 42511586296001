import Vue from 'vue'

const url = process.env.VUE_APP_OFFICE_URL

export const sendSms = (
  message: string,
  phoneNumber: string,
  forceStaging: string
) => {
  const data = { message, phoneNumber, forceStaging }
  return Vue.axios.post(`${url}/sms/send`, data)
}

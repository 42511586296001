










































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { TopUp, TopUpDto } from '@/types/topUp'
import firebase from 'firebase/app'
import 'firebase/database'
import { vxm } from '../store'
import Topup from '@/components/Admin/TopUp.vue'
import { sendNotify } from '@/utils/lineNotify'
import { sendSms } from '@/utils/sms'

@Component({
  components: {
    Topup,
  },
  computed: {
    search: () => vxm.globalSearch.getVal,
  },
})
export default class extends Vue {
  private ref?: firebase.database.Reference
  private fetching = false
  private showHidden = false
  private topUps: TopUp[] = []
  private dialog = false
  private imageUrl = ''
  private headers = [
    {
      text: 'Student ID',
      value: 'username',
    },
    {
      text: 'เวลา',
      value: 'timestamp',
      sort: (a: Date, b: Date) => a.getTime() - b.getTime(),
    },
    {
      text: 'ชื่อ',
      value: 'name',
    },
    {
      text: 'วิชา',
      value: 'subject',
    },
    {
      text: 'Package',
      value: 'package',
    },
    {
      text: 'Actions',
      value: 'imageUrl',
      sortable: false,
      filterable: false,
    },
    {
      text: 'Color',
      value: 'color',
    },
    {
      text: 'Remark',
      value: 'remark',
    },
    {
      text: 'Note',
      value: 'note',
      sortable: false,
    },
  ]
  private topupDialog = false
  private smsDialog = false
  private changeItem!: TopUp
  private colorTags = [
    'grey',
    'red',
    'yellow',
    'green',
    'blue',
    'purple',
    'pink lighten-4',
    'orange lighten-2',
  ]

  get topUpList() {
    if (this.showHidden) {
      return this.topUps.sort(
        (a, b) => b.timestamp.valueOf() - a.timestamp.valueOf()
      )
    }
    return this.topUps
      .filter(topUp => !topUp.hidden)
      .sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf())
  }

  async mounted() {
    await vxm.allUsers.fetchUsers()
    this.topUps = []
    this.fetching = true
    this.ref?.off()
    this.ref = firebase.database().ref('pr/top-up')
    this.ref?.on('value', snapshot => {
      if (snapshot.val()) {
        const topUps: TopUp[] = Object.entries(snapshot.val()).flatMap(
          ([username, vals]) =>
            Object.entries(vals as Record<string, TopUpDto>).map(([k, v]) => {
              const date = new Date(+k)
              const user = vxm.allUsers.getStudentNameById(+username)
              const searchableValue = [
                user?.firstname,
                user?.lastname,
                user?.nickname,
                user?.firstnameEn,
                user?.lastnameEn,
                user?.nicknameEn,
                username,
                v.package,
                v.subject,
                this.getDateTimeString(date),
              ]
              return {
                username,
                name: `${user?.firstname} (${user?.nickname})`,
                timestamp: date,
                searchString: searchableValue.join('|'),
                ...v,
              }
            })
        )
        this.topUps = topUps
      }
      this.fetching = false
    })
  }

  private searchDataTables(
    value: unknown,
    search: string | null,
    item: TopUp & { searchString: string }
  ): boolean {
    return search
      ? search
          .split(' ')
          .map(s => item.searchString.indexOf(s.trim()) > -1)
          .reduce((previous, current) => previous && current, true)
      : true
  }

  private getDateTimeString(date: Date) {
    return `${date.getDate()}/${date.getMonth() +
      1}/${date.getFullYear()} ${date.getHours()}:${(
      '0' + date.getMinutes()
    ).slice(-2)}`
  }

  private async toggleHide(topUp: TopUp) {
    this.fetching = true
    await this.ref?.update({
      [`${topUp.username}/${topUp.timestamp.valueOf()}/hidden`]: !topUp.hidden,
    })
    this.fetching = false
  }

  private async changeColor(color: string) {
    this.fetching = true
    await this.ref?.update({
      [`${
        this.changeItem?.username
      }/${this.changeItem?.timestamp.valueOf()}/color`]: color,
    })
    this.fetching = false
  }

  private async changeRemark(remark: string) {
    this.fetching = true
    await this.ref?.update({
      [`${
        this.changeItem?.username
      }/${this.changeItem?.timestamp.valueOf()}/remark`]: remark,
    })
    this.fetching = false
  }

  private async changeStep(item: TopUp, step: number) {
    if (step > (item.step || 0)) {
      await this.ref?.update({
        [`${item.username}/${item.timestamp.valueOf()}/step`]: step,
      })
    }
  }

  private async addNote(item: TopUp, note: string) {
    if (note) {
      await this.ref?.update({
        [`${item.username}/${item.timestamp.valueOf()}/note`]: note,
      })
    }
  }

  private async deleteData(item: TopUp) {
    this.fetching = true
    await this.ref?.update({
      [`${item.username}/${item.timestamp.valueOf()}`]: null,
    })
    this.fetching = false
  }

  private async sendSms(topUp: TopUp) {
    this.smsDialog = true
    try {
      const { data: userData } = await Vue.axios.get(
        `${process.env.VUE_APP_MAIN_URL}/v1/user/${topUp.username}`,
        {
          headers: {
            authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
          },
        }
      )
      try {
        let subj = ''
        switch (topUp.subject) {
          case 'math':
            subj = 'วิชาคณิตศาสตร์'
            break
          case 'physics':
            subj = 'วิชาฟิสิกส์'
            break
          case 'chem':
            subj = 'วิชาเคมี'
            break
          case 'english':
            subj = 'วิชาภาษาอังกฤษ'
            break
          default:
            break
        }
        const fullname = `${userData.info.Firstname} (${userData.info.Nickname})`
        const pack = topUp.package
        // const money = pack.slice(0, pack.indexOf('('))
        const coin = pack.slice(pack.indexOf('(') + 1, pack.indexOf(')'))
        const notifyText = `\nระบบได้เติม ${coin} เหรียญ เข้าบัญชี "${subj}" ของ ${fullname} เรียบร้อยแล้ว`
        await sendSms(
          `ขอบคุณที่ชำระเงิน\nระบบได้เติมเหรียญ เข้าบัญชี "${subj}" ของ ${fullname} เรียบร้อยแล้ว สามารถตรวจสอบจำนวนยอดเหรียญคงเหลือทาง https://live.monkey-monkey.com/hybrid`,
          `${userData.info.Phone}`,
          'false'
        )
        try {
          await sendNotify('AdminMonkey', notifyText)
        } catch (error) {
          // empty
        }
      } catch (error) {
        // empty
      } finally {
        this.smsDialog = false
      }
    } catch (error) {
      alert('Can not get tel no.')
    }
  }

  private showTopupDialog(username: string, subject: string) {
    let subjKey = 1
    switch (subject) {
      case 'math':
        subjKey = 1
        break
      case 'physics':
        subjKey = 2
        break
      case 'chem':
        subjKey = 3
        break
      case 'english':
        subjKey = 4
        break
      default:
        break
    }
    vxm.userTransaction.fetchUserTransacction(username + subjKey)
    this.topupDialog = true
  }

  private getSubjectColor(subject: string): string {
    switch (subject) {
      case 'math':
        return 'amber accent-2'
      case 'physics':
        return 'deep-purple lighten-3'
      case 'chem':
        return 'blue lighten-3'
      case 'english':
        return 'grey lighten-1'
      default:
        return 'white'
    }
  }
}

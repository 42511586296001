var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"px-2"},[_c('v-card-title',[_c('div',{staticClass:"title"},[_vm._v("New Student Regis")]),_c('v-spacer'),_c('v-btn',{attrs:{"label":"แสดงที่ซ่อน"},on:{"click":function($event){_vm.showHidden = !_vm.showHidden}},model:{value:(_vm.showHidden),callback:function ($$v) {_vm.showHidden=$$v},expression:"showHidden"}},[_vm._v(" "+_vm._s(_vm.showHidden ? 'ซ่อน' : 'แสดงที่ซ่อน')+" ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.registerList,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:_vm.isNear(item.testDate) ? 'teal accent-2' : ''},[_c('td',[_c('div',[_vm._v(_vm._s(_vm.getDateTimeString(item.timestamp)))]),_c('div',{staticClass:"caption grey--text lighten-2"},[_vm._v(" "+_vm._s(item.key)+" ")])]),_c('td',[_vm._v(_vm._s(item.fullName))]),_c('td',[_vm._v(_vm._s(_vm.getDateInDayText(item.testDate)))]),_c('td',[_vm._v(_vm._s(item.grade))]),_c('td',[_vm._v(_vm._s(item.school))]),_c('td',[(item.interestedLesson === 'check')?_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.changeInterestedLesson(item.key, '')}}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.changeInterestedLesson(item.key, 'check')}}},[_vm._v(" mdi-close-circle ")])],1),_c('td',[(item.suggestId === 'check')?_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.changeSuggestId(item.key, '')}}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.changeSuggestId(item.key, 'check')}}},[_vm._v(" mdi-close-circle ")])],1),_c('td',[_c('v-menu',{attrs:{"left":"","origin":"right center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.changeItem = item}}},on),[_c('v-icon',{attrs:{"color":item.color ? item.color : 'grey'}},[_vm._v(" mdi-checkbox-blank-circle ")])],1)]}}],null,true)},[_c('div',{staticClass:"d-flex pa-2 white"},_vm._l((_vm.colorTags),function(tag){return _c('v-btn',{key:("color-select-" + tag),attrs:{"icon":""},on:{"click":function($event){return _vm.changeColor(item.key, tag)}}},[_c('v-icon',{attrs:{"color":tag}},[_vm._v(" mdi-checkbox-blank-circle ")])],1)}),1)])],1),_c('td',[_vm._v(_vm._s(item.phoneNumber))]),_c('td',[_vm._v(_vm._s(item.lineId))]),_c('td',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":item.step > 0 ? 'grey' : 'secondary',"depressed":"","small":""},on:{"click":function($event){_vm.changeStep(item, 1)
                _vm.imgUrl = item.profileUrl
                _vm.showImg = true}}},[_vm._v(" Profile ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":item.step > 1 ? 'grey' : 'secondary',"depressed":"","small":""},on:{"click":function($event){_vm.changeStep(item, 2)
                _vm.imgUrl = item.recieptUrl
                _vm.showImg = true}}},[_vm._v(" Reciept ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":item.step > 2 ? 'grey' : 'secondary',"depressed":"","small":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v(" Create ID ")]),_c('v-btn',{attrs:{"color":item.hidden ? 'grey' : 'secondary',"depressed":"","small":""},on:{"click":function($event){return _vm.toggleList(item)}}},[_vm._v(" "+_vm._s(item.hidden ? 'SHOW' : 'HIDE')+" ")])],1)])]}}])})],1),_c('v-dialog',{attrs:{"width":"300px","persistent":""},model:{value:(_vm.createStudentDialog),callback:function ($$v) {_vm.createStudentDialog=$$v},expression:"createStudentDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("New Student")]),_c('v-container',[_c('v-text-field',{attrs:{"solo":"","hide-details":""},model:{value:(_vm.studentId),callback:function ($$v) {_vm.studentId=$$v},expression:"studentId"}})],1),_c('v-card-text',{staticClass:"pb-0"},[_vm._v(_vm._s(_vm.createDesc))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error","disabled":_vm.onCreate},on:{"click":function($event){_vm.createStudentDialog = false
            _vm.getNextStudentId()}}},[_vm._v(" CANCEL ")]),_c('v-btn',{attrs:{"color":"secondary","depressed":"","loading":_vm.onCreate},on:{"click":_vm.createUser}},[_vm._v(" CREATE ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"300px","persistent":""},model:{value:(_vm.onError),callback:function ($$v) {_vm.onError=$$v},expression:"onError"}},[_c('v-card',[_c('v-card-title',[_vm._v("Error")]),_c('v-card-text',[_vm._v(_vm._s(_vm.error))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.onError = false}}},[_vm._v(" CANCEL ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"1024px"},model:{value:(_vm.showImg),callback:function ($$v) {_vm.showImg=$$v},expression:"showImg"}},[_c('v-card',[_c('v-img',{attrs:{"max-height":"80vh","contain":"","src":_vm.imgUrl},on:{"click":function($event){_vm.showImg = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }





































































































































































































import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/database'
import { vxm } from '../store'
import { RegisterList } from '@/types/registerList'
import { apolloClient } from '@/utils/graphql'
import gql from 'graphql-tag'
import { sendSms } from '@/utils/sms'
import { sendNotify } from '@/utils/lineNotify'

@Component({
  computed: {
    search: () => vxm.globalSearch.getVal,
  },
})
export default class extends Vue {
  private ref?: firebase.database.Reference
  private data: RegisterList[] = []
  private loading = true
  private headers = [
    {
      text: 'เวลาที่สมัคร',
      value: 'timestamp',
      sort: (a: Date, b: Date) => a.getTime() - b.getTime(),
    },
    {
      text: 'ชื่อ',
      value: 'fullName',
    },
    {
      text: 'วันนัดสอบ',
      value: 'testDate',
      sort: (a: Date, b: Date) => a.getTime() - b.getTime(),
    },
    // {
    //   text: 'Subject',
    //   value: 'subject',
    // },
    {
      text: 'ชั้น',
      value: 'grade',
    },
    {
      text: 'โรงเรียน',
      value: 'school',
      sortable: false,
      filterable: false,
    },
    {
      text: 'IsTest',
      value: 'interestedLesson',
      sortable: false,
      filterable: false,
    },
    {
      text: 'IsRegis',
      value: 'suggestId',
      sortable: false,
      filterable: false,
    },
    {
      text: 'Color',
      value: 'color',
    },
    {
      text: 'เบอร์โทร',
      value: 'phoneNumber',
      sortable: false,
      filterable: false,
    },
    {
      text: 'Line',
      value: 'lineId',
      sortable: false,
      filterable: false,
    },
    {
      text: 'Actions',
      value: 'step',
      sortable: false,
      filterable: false,
    },
  ]
  private createStudentDialog = false
  private studentId = 'Loading...'
  private selectedItem?: RegisterList
  private onCreate = false
  private createDesc = 'Waiting for create.'
  private onError = false
  private error = ''
  private studentPassword = ''
  private showImg = false
  private imgUrl = ''
  private showHidden = false
  private colorTags = [
    'red',
    'yellow',
    'green',
    'blue',
    'purple',
    'pink lighten-4',
    'orange lighten-2',
  ]

  private get registerList() {
    return this.data.filter(el => this.showHidden || !!el.hidden === false)
  }

  mounted() {
    this.ref?.off()
    this.ref = firebase.database().ref('pr/reserve')
    this.ref.on('value', snapshot => {
      const data = snapshot.val()
      if (data) {
        const enrolData = Object.keys(data).map(k => ({
          key: k,
          timestamp: new Date(+k),
          email: data[k].info.email,
          firstName: data[k].info.firstName || '',
          lastName: data[k].info.lastName || '',
          nickName: data[k].info.nickName || '',
          fullName: `${data[k].info.firstName} (${data[k].info.nickName}) ${data[k].info.lastName}`,
          firstNameEn: data[k].info.firstNameEn || '',
          lastNameEn: data[k].info.lastNameEn || '',
          nickNameEn: data[k].info.nickNameEn || '',
          fullNameEn: `${data[k].info.firstNameEn} (${data[k].info.nickNameEn}) ${data[k].info.lastNameEn}`,
          school: data[k].info.school,
          grade: data[k].info.grade,
          interestedLesson: data[k].info.interestedLesson,
          suggestId: data[k].info.recommendedById,
          subject: data[k].subject,
          package: data[k].package,
          phoneNumber: data[k].phoneNumber,
          testDate: new Date(data[k].testDate),
          profileUrl: data[k].profileUrl || '',
          recieptUrl: data[k].recieptUrl || '',
          hidden: !!data[k].hidden,
          color: data[k].color,
          step: data[k].step || 0,
          lineId: data[k].info.parentLineId || '',
        })) as RegisterList[]
        this.data = enrolData.sort((a, b) => {
          const today = new Date()
          let valA = a.testDate.getTime()
          let valB = b.testDate.getTime()
          if (valA < today.getTime()) {
            valA += 1000000
          }
          if (valB < today.getTime()) {
            valB += 1000000
          }
          return valB - valA
        })
        this.loading = false
      }
    })
    this.getNextStudentId()
  }

  private getDateTimeString(date: Date) {
    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const hour = ('0' + date.getHours()).slice(-2)
    const minute = ('0' + date.getMinutes()).slice(-2)
    return `${day}/${month}/${date.getFullYear()} ${hour}:${minute}`
  }

  private getDateInDayText(date: Date) {
    let dow = ''
    switch (date.getDay()) {
      case 0:
        dow = 'อาทิตย์'
        break
      case 1:
        dow = 'จันทร์'
        break
      case 2:
        dow = 'อังคาร'
        break
      case 3:
        dow = 'พุธ'
        break
      case 4:
        dow = 'พฤหัส'
        break
      case 5:
        dow = 'ศุกร์'
        break
      case 6:
        dow = 'เสาร์'
        break
      default:
        dow = ''
        break
    }
    return `${dow} ${this.getDateTimeString(date)}`
  }

  private isNear(testDate: Date) {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const bookingDay = new Date(testDate)
    bookingDay.setHours(0, 0, 0, 0)
    return (
      bookingDay.getTime() - today.getTime() <= 6 * 24 * 60 * 60 * 1000 &&
      bookingDay.getTime() - today.getTime() > 0
    )
  }

  private async toggleList(item: RegisterList) {
    await this.ref?.update({ [`${item.key}/hidden`]: !item.hidden })
  }

  private openDialog(item: RegisterList) {
    this.selectedItem = item
    this.createStudentDialog = true
  }

  private async getNextStudentId() {
    const url = process.env.VUE_APP_MAIN_URL
    const token = process.env.VUE_APP_TOKEN
    try {
      const nextId = await this.axios.get<{ studentID: number }>(
        `${url}/v1/user/nextStudent`,
        { headers: { authorization: `Bearer ${token}` } }
      )
      this.studentId = nextId.data.studentID + ''
    } catch (error) {
      this.studentId = 'Loading...'
    }
  }

  private async createUser() {
    this.onCreate = true
    const mode = process.env.NODE_ENV
    let isSuccess = true
    if (mode === 'production') {
      try {
        this.createDesc = 'Create user in student site.'
        await this.createUserInStudent()
        this.createDesc = 'Create user in auth/office site.'
        await this.setUserDataInOffice()
        this.createDesc = 'Update info in student site.'
        await this.setUserDataInStudent()
      } catch (error) {
        isSuccess = false
      }
    }
    if (isSuccess) {
      if (this.selectedItem) {
        await this.changeStep(this.selectedItem, 3)
        await sendSms(
          `ขอบคุณที่สมัครเรียนที่ Monkey Monkey\nระบบขอแจ้งรหัสผู้ใช้งาน (ID) และรหัสผ่าน (Password) ของ ${
            this.selectedItem.firstName
          } (${this.selectedItem.nickName})\nID: ${
            mode === 'development' ? '15103' : this.studentId
          }\nPassword: ${
            mode === 'development' ? '4171' : this.studentPassword
          }\nท่านสามารถเข้าสู่ระบบและทำตามขั้นตอนต่อไปทาง https://monkey-monkey.com`,
          this.selectedItem.phoneNumber,
          'false'
        )
        try {
          await sendNotify(
            'AdminMonkey',
            `\nรหัสผู้ใช้งาน (ID) และรหัสผ่าน (Password) ของ ${
              this.selectedItem.firstName
            } (${this.selectedItem.nickName})\nID: ${
              mode === 'development' ? '15103' : this.studentId
            }\nPassword: ${
              mode === 'development' ? '4171' : this.studentPassword
            }\nเปิดใช้งานแล้ว`
          )
        } catch (error) {
          // empty
        }
      }
      this.createDesc = 'Set step for new student.'
      const firebaseRef = firebase.database().ref('pr/new-user')
      const updateId = mode === 'development' ? '15103' : this.studentId
      await firebaseRef.update({
        [updateId]: {
          step: '2.1',
          testDate: this.selectedItem?.testDate.getTime(),
          email: this.selectedItem?.email,
        },
      })
      firebaseRef.off()
    }
    this.createStudentDialog = false
    this.onCreate = false
    this.selectedItem = undefined
    this.createDesc = 'Waiting for create.'
    this.getNextStudentId()
  }

  private hasError(desc: string) {
    this.error = desc
    this.onError = true
  }

  private async changeInterestedLesson(key: string, lesson: string) {
    await this.ref?.update({
      [`${key}/info/interestedLesson`]: lesson,
    })
  }

  private async changeSuggestId(key: string, suggestId: string) {
    await this.ref?.update({
      [`${key}/info/recommendedById`]: suggestId,
    })
  }

  private async changeColor(key: string, color: string) {
    await this.ref?.update({
      [`${key}/color`]: color,
    })
  }

  private async changeStep(item: RegisterList, step: number) {
    if (step > (item.step || 0)) {
      await this.ref?.update({
        [`${item.key}/step`]: step,
      })
    }
  }

  private async createUserInStudent() {
    const url = process.env.VUE_APP_MAIN_URL
    const token = process.env.VUE_APP_TOKEN
    if (this.selectedItem) {
      await this.axios.post<{
        student: { userID: number; password: string }
      }>(
        `${url}/v1/user/addStudent`,
        { nextStudentID: this.studentId },
        { headers: { authorization: `Bearer ${token}` } }
      )
    }
  }

  private async setUserDataInOffice() {
    interface CreateUserInput {
      username: string
      firstName: string
      firstNameEn: string
      lastName: string
      lastNameEn: string
      nickname: string
      nicknameEn: string
      phone: string[]
    }
    if (this.selectedItem) {
      const mutateUser = apolloClient.mutate<{
        createUser: { username: string; password: string }
      }>({
        mutation: gql`
          mutation Users($data: CreateUserInput!) {
            createUser(data: $data) {
              username
              password
            }
          }
        `,
        variables: {
          data: {
            username: this.studentId,
            firstName: this.selectedItem.firstName,
            firstNameEn: this.selectedItem.firstNameEn,
            lastName: this.selectedItem.lastName,
            lastNameEn: this.selectedItem.lastNameEn,
            nickname: this.selectedItem.nickName,
            nicknameEn: this.selectedItem.nickNameEn,
            phone: [this.selectedItem.phoneNumber],
          },
        },
      })
      try {
        const res = await mutateUser
        if (res.data) {
          this.studentPassword = res.data.createUser.password
        }
      } catch (error) {
        this.hasError('Can not fill student data in office.')
      }
    }
  }

  private async setUserDataInStudent() {
    const url = process.env.VUE_APP_MAIN_URL
    const token = process.env.VUE_APP_TOKEN
    if (this.selectedItem) {
      const data = {
        firstname: this.selectedItem.firstName,
        lastname: this.selectedItem.lastName,
        nickname: this.selectedItem.nickName,
        firstnameEn: this.selectedItem.firstNameEn,
        lastnameEn: this.selectedItem.lastNameEn,
        nicknameEn: this.selectedItem.nickNameEn,
        email: this.selectedItem.email,
        phone: this.selectedItem.phoneNumber,
        status: 'active',
        password: this.studentPassword,
      }
      await this.axios.patch(`${url}/v1/user/edit/${this.studentId}`, data, {
        headers: { authorization: `Bearer ${token}` },
      })
    }
  }
}



































































































import { Component, Vue } from 'vue-property-decorator'
import firebase, { database } from 'firebase/app'
import 'firebase/database'
import { vxm } from '../store'

interface NewUserData {
  key: string
  username: string
  step: string
  testDate: number
  testDateObject: Date
  testUrl?: string
  summaryUrl?: string
  email?: string
}

@Component
export default class extends Vue {
  private ref?: firebase.database.Reference
  private data: NewUserData[] = []
  private selectedUsername = ''
  private type = ''
  private dialog = false
  private url = ''
  private loading = false
  private onStepChange = false
  private headers = [
    {
      text: 'Student ID',
      value: 'username',
    },
    {
      text: 'ชื่อ',
      value: 'name',
    },
    {
      text: 'วันที่สอบ',
      value: 'testDateObject',
    },
    {
      text: 'E-Mail',
      value: 'email',
    },
    {
      text: 'Step',
      value: 'step',
    },
    {
      text: 'Actions',
      value: 'key',
      sortable: false,
      filterable: false,
    },
  ]
  private stepArr = [
    { text: 'ภาพรวม EHB', value: '2.1' },
    { text: 'การเข้าสู่ Web', value: '2.2' },
    { text: 'การเรียนในห้อง live', value: '2.3' },
    { text: 'การเรียนนอก live', value: '2.4' },
    { text: 'ตาราง/เงิน/เติมเงิน', value: '2.5' },
    { text: 'Office lens', value: '2.6' },
    { text: 'รอถึงวันสอบ', value: '3' },
    { text: 'เตรียมสอบ', value: '4.1' },
    { text: 'รอสอบ', value: '4.2' },
    { text: 'ทำข้อสอบ', value: '4.3' },
    { text: 'รอตรวจ', value: '4.4' },
    { text: 'รอยืนยัน', value: '4.5' },
  ]

  mounted() {
    vxm.allUsers.fetchUsers()
    this.ref?.off()
    this.ref = firebase.database().ref('pr/new-user')
    this.ref.on('value', snapshot => {
      const newUser = snapshot.val()
      if (newUser) {
        this.data = Object.keys(newUser).map(username => {
          const user = vxm.allUsers.getStudentNameById(+username)
          return {
            key: username,
            username,
            ...newUser[username],
            name: `${user?.firstname}(${user?.nickname}) ${user?.lastname}`,
            testDateObject: new Date(+newUser[username].testDate),
          }
        })
      }
    })
  }

  private openDialog(username: string, type: string) {
    this.selectedUsername = username
    this.type = type
    this.dialog = true
  }

  private closeDialog() {
    this.selectedUsername = ''
    this.url = ''
    this.type = ''
    this.dialog = false
    this.loading = false
  }

  private async submitUrl() {
    if (this.type === 'test') {
      this.loading = true
      await this.ref?.update({
        [`${this.selectedUsername}/testUrl`]: this.url,
      })
      this.closeDialog()
    } else if (this.type === 'summary') {
      this.loading = true
      await this.ref?.update({
        [`${this.selectedUsername}/summaryUrl`]: this.url,
      })
      this.closeDialog()
    }
  }

  private async changeStep(username: string, step: string) {
    this.onStepChange = true
    await this.ref?.update({
      [`${username}/step`]: step,
    })
    this.onStepChange = false
  }

  private twoDigit(num: number) {
    return ('00' + num).slice(-2)
  }

  private dayOfWeekName(dow: number): string {
    const dowArr = [
      'อาทิตย์',
      'จันทร์',
      'อังคาร',
      'พุธ',
      'พฤหัส',
      'ศุกร์',
      'เสาร์',
    ]
    return dowArr[dow]
  }

  private monthName(month: number): string {
    const monthName = [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ]
    return monthName[month]
  }
}
